(function($) {


	var $fromLocationInput = $('.js-search-bar-form #fromLocation');
	var $toLocationInput = $('.js-search-bar-form #toLocation');
	var $dateDepInput = $('.js-search-bar-form #dateDep');

	$fromLocationInput.autocomplete({
		minLength: 0,
		source: _gConfig.cityFrom,
		focus: function( event, ui ) {
			$fromLocationInput.val( ui.item.label );
			return false;
		},
		select: function( event, ui ) {
			$fromLocationInput.val( ui.item.label );
			$("#fromLocationId" ).val( ui.item.value );
			return false;
		}
	});

	var cache = {};
	$toLocationInput.autocomplete({
		minLength: 0,
		source: function(request, response) {
			var term = request.term;
			if ( term in cache ) {
				response( cache[ term ] );
				return;
			}
			$.ajax({
				url: "/api/searchLocality",
				data: {domain: _gConfig.domain, search : request.term, lang: _gConfig.lang},
				dataType: "json",
				type: "POST",
				dataFilter: function(data) { return data; },
				success: function(data) {
					var results = $.map(data, function(item){
						return { value: item.value, label: item.label }
					});
					cache[request.term] = results;
					response(results);
				}
				//error: HandleAjaxError  // custom method
			});
		},

		focus: function( event, ui ) {
			$toLocationInput.val( ui.item.label );
			return false;
		},
		select: function( event, ui ) {
			$toLocationInput.val( ui.item.label );
			$("#toLocationId" ).val( ui.item.value );
			return false;
		},
		open: function (event, ui ) {
			$("#toLocationId" ).val('');
		}
	});

	$dateDepInput.datepicker({
		dateFormat: "dd.mm.yy",
		minDate: new Date($dateDepInput.data('minDate').substr(0, 4), $dateDepInput.data('minDate').substr(5, 2)-1, $dateDepInput.data('minDate').substr(8, 2)),
		maxDate: new Date($dateDepInput.data('maxDate').substr(0, 4), $dateDepInput.data('maxDate').substr(5, 2)-1, $dateDepInput.data('maxDate').substr(8, 2))
	});


})(jQuery);
